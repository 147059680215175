<template>
  <div class="alarmRulesDetail">
    <el-button style="margin-bottom: 15px" class="iconfont icon-dcx" size="medium" @click="$router.go(-1)">关闭</el-button>
    <baseSection name="基本信息">
      <el-row style="margin-bottom: 20px">
        <el-col :offset="1" :span="7">
          <span style="color: #5a5a5a">报警内容： </span> <span style="color: black" class="alarmDetailText">{{ data.name }}</span>
        </el-col>
        <el-col :offset="1" :span="7">
          <span style="color: #5a5a5a">报警编号： </span> <span style="color: black" class="alarmDetailText">{{ data.code }}</span>
        </el-col>
        <el-col :offset="1" :span="7">
          <span style="color: #5a5a5a">启用状态： </span><span style="color: black" class="alarmDetailText">{{ data.status === 0 ? '停用' : '启用' }}</span>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 20px">
        <el-col :offset="1" :span="7">
          <span style="color: #5a5a5a">描述备注： </span> <span style="color: black" class="alarmDetailText">{{ data.remark }}</span>
        </el-col>
        <el-col :offset="1" :span="7">
          <span style="color: #5a5a5a">报警规则： </span><span style="color: black" class="alarmDetailText">{{ data.isEasy === 0 ? '复杂' : '简单' }}</span>
        </el-col>
      </el-row>
    </baseSection>
    <baseSection name="报警参数">
      <el-table :data="data.alarmParams" border style="width: 100%; margin-bottom: 20px">
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column prop="tag" label="参数代码"> </el-table-column>
        <el-table-column prop="paramName" label="参数名称"> </el-table-column>
        <el-table-column prop="valueType" label="值类型" width="180"> </el-table-column>
      </el-table>
    </baseSection>
    <baseSection name="查看报警规则">
      <div class="flow-container" ref="flowContainer" v-if="nodeList">
        <super-flow ref="superFlow" :node-list="nodeList" :link-list="linkList">
          <template v-slot:node="{ meta }">
            <div class="flow-node ellipsis">
              {{ meta.name }}
            </div>
          </template>
        </super-flow>
      </div>
    </baseSection>
    <baseSection name="报警推送">
      <el-row style="margin-bottom: 20px">
        <el-col :offset="1" :span="7">
          <span style="color: #5a5a5a">邮箱地址： </span> <span style="color: black; font-size: 16px" class="alarmDetailText" v-if="data.emails">{{ data.emails[0].email }}</span>
        </el-col>
        <el-col :offset="1" :span="7">
          <span style="color: #5a5a5a">邮箱内容： </span> <span style="color: black; font-size: 16px" class="alarmDetailText" v-if="data.emails">{{ data.emails[0].content }}</span>
        </el-col>
      </el-row>
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Base64 from '../base64.js';
import SuperFlow from 'vue-super-flow';
import 'vue-super-flow/lib/index.css';
export default {
  data() {
    return {
      data: {},
      alarmId: '',
      nodeList: [],
      linkList: []
    };
  },
  components: { SuperFlow, baseSection },
  created() {
    if (this.$route.query.data.alarmId) {
      this.alarmId = this.$route.query.data.alarmId;
      localStorage.setItem('alarmId', this.$route.query.data.alarmId);
    } else {
      this.alarmId = localStorage.getItem('alarmId');
    }
    this.init();
  },
  methods: {
    async init() {
      const res = await this.$apis.AlarmConfig.get({ alarmId: this.alarmId });
      this.data = res;
      this.nodeList = JSON.parse(Base64.decode(res.flowObject)).nodeList;
      this.linkList = JSON.parse(Base64.decode(res.flowObject)).linkList;
    }
  }
};
</script>
<style scoped lang="scss">
.super-flow {
  height: 250px;
  background-color: #f5f5f5;
}
</style>
